<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col
                        md="4"
                        v-if="this.$store.getters['app/approvalAccess']('Receipt')"
                      >
                        <b-form-group>
                          <label>Book By</label>
                          <v-select
                            @input="getEmployee($event)"
                            v-model="bookby"
                            placeholder="None"
                            label="name"
                            :options="bookByOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="bookby && bookby.name !== 'Admin'">
                        <b-form-group>
                          <label
                            >Select
                            {{
                              bookby && bookby.name == "Admin"
                                ? "Employee"
                                : bookby
                                ? bookby.name
                                : ""
                            }}</label
                          >
                          <v-select
                            v-model="employee"
                            label="fullname"
                            placeholder="None"
                            :options="employeeOptions"
                            @input="getClients"
                          >
                            <template
                              #option="{ name, profile_image, username, surname }"
                            >
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder">
                                {{ name }} {{ surname }}</span
                              ><span>({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Client</label>
                          <v-select
                            v-model="client"
                            label="fullname"
                            placeholder="None"
                            :options="$store.state.master.customersLandSales"
                            @input="getAllLands"
                          >
                            <template #option="{ fullname, profile_image }">
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder"> {{ fullname }}</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group>
                          <label>Land</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Land"
                          >
                            <v-select
                              v-model="land"
                              label="villagename"
                              :options="landOptions"
                              placeholder="None"
                              @input="handleclickLand($event)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>New Survay No.</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="New Survay No"
                          >
                            <v-select
                              v-model="surveynonew"
                              label="surveynonew"
                              :options="survayNoOption"
                              placeholder="None"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <b-card-code id="landstatement">
                <report-table :data="installment_items" :columns="fields">
                  <template #download>
                    <div class="d-flex align-items-center justify-content-end">
                      <b-button
                        v-b-tooltip.hover.v-success
                        title="Print"
                        variant="gradient-success"
                        class="ml-2 btn-icon"
                        @click="printData"
                      >
                        <feather-icon icon="PrinterIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.v-success
                        title="Excel"
                        variant="gradient-success"
                        class="ml-2 btn-icon"
                        @click="clickExcel"
                      >
                        <!-- @click="excelData" -->
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </div>
                  </template>
                  <template #upperbody>
                    <div class="upper-body-data mb-5">
                      <table
                        border="1"
                        cellpadding="2"
                        class="table table-bordered"
                        style="border-collapse: collapse; min-width: 22cm; width: 100%"
                      >
                        <tbody class="text">
                          <tr>
                            <td colspan="5">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                "
                              >
                                <div><b>Client : </b>{{ land_data.customer }}</div>
                                <div
                                  v-if="sales.customer && sales.customer.profile_image"
                                >
                                  <b-avatar
                                    style="height: 50px; width: 50px"
                                    :src="getprofileImage(sales.customer.profile_image)"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <b>Village Name : </b
                              >{{ land_data ? land_data.villagename : "" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>New Survay No.</b></td>
                            <td>{{ land_data.surveynonew }}</td>
                            <td><b>Land Deal Amount</b></td>
                            <td>{{ sales.totalpayment }}</td>
                          </tr>
                          <tr>
                            <td><b>Zone</b></td>
                            <td>{{ land_data.zone ? land_data.zone.name : "" }}</td>
                            <td><b>With NA?</b></td>
                            <td>{{ sales.withna }}</td>
                          </tr>
                          <tr>
                            <td><b>Land area (in sq.yard)</b></td>
                            <td>{{ land_data.fparea }}</td>
                            <td><b>Down Payment</b></td>
                            <td>{{ sales.firstpayment }}</td>
                          </tr>
                          <tr>
                            <td><b>Rate per sq. yard</b></td>
                            <td>{{ sales.landrate }}</td>
                            <td><b>TP</b></td>
                            <td>
                              {{ land_data.tpno }}
                              <!-- {{
                                sales.booktype != "Full Payment" &&
                                sales.noofinstallment &&
                                sales.installmentamt
                                  ? sales.installmentamt / sales.noofinstallment
                                  : ""
                              }} -->
                            </td>
                          </tr>
                          <tr>
                            <td><b>Payment Schedule</b></td>
                            <td>
                              {{
                                sales.paymenttermday
                                  ? sales.paymenttermday +
                                    (sales.paymenttermday &&
                                    sales.paymenttermday.includes("day")
                                      ? ""
                                      : " days")
                                  : ""
                              }}
                            </td>
                            <td><b>Sub TP</b></td>
                            <td>
                              {{ land_data.subtpno }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Booking Date</b></td>
                            <td>
                              {{
                                sales.bookingdate
                                  ? moment(sales.bookingdate).format("DD/MM/yyyy")
                                  : ""
                              }}
                            </td>
                            <td><b>Sale by Name & Team</b></td>
                            <td>
                              {{ teamname }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Remarks</b></td>
                            <td colspan="3">
                              {{ sales.remarks }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody></tbody>
                      </table>
                    </div>
                  </template>
                  <template #action="{ row }">
                    <div class="nonPrintable">
                      <b-tooltip
                        :id="'download' + row.id"
                        :target="'download' + row.id"
                        triggers="hover"
                      >
                        Download
                      </b-tooltip>
                    </div>
                  </template>
                </report-table>
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Receipt"
      :html2canvas="{}"
      :jsPDF="{
        format: 'a5',
      }"
      :pdf-quality="3"
      :manual-pagination="false"
      pdf-format="a5"
      :pdf-margin="0"
      pdf-orientation="landscape"
      pdf-content-width="1800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <print-comp :receiptData="receiptData" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import printComp from "@/views/print/printReceipt.vue";

import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import attachment from "@/components/Attechment.vue";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  VBTooltip,
  BAvatar,
  BBadge,
  BTooltip,
} from "bootstrap-vue";
import VueHtml2pdf from "vue-html2pdf";

import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    printComp,
    BBadge,
    BTooltip,
    attachment,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BAvatar,
    GoodTableColumnSearch,
    VueHtml2pdf,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      moment,
      result: "",
      teamname: "",
      data: "",
      projectname: "",
      projectOptions: [],
      bookby: "",
      employee: "",
      client: "",
      employeeOptions: [],
      sales: {},
      land_data: {},
      installment_items: [],
      status: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      receiptData: {},
      fields: [
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Date",
          field: "installment_date",
        },
        // {
        //   label: "EMI No.",
        //   field: "emino",
        // },
        {
          label: "Payment",
          field: "installment_amount",
        },

        {
          label: "Total Received",
          field: "paid",
        },
        {
          label: "Balance Amount",
          field: "balance",
        },
        // {
        //   label: "Remarks",
        //   field: "remarks",
        // },
        // {
        //   label: "Ref No",
        //   field: "utrno",
        // },
        // {
        //   label: "Rece Bank",
        //   field: "bankname",
        // },
      ],
      bookByOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Employee", id: "employee" },
        { name: "Admin", id: "admin" },
      ],
      lastReceiptDate: "",
      surveynonew: "",
      survayNoOption: [],
      landOptions: [],
      allLands: [],
      land: "",
    };
  },
  mounted() {
    if (!this.$store.getters["app/approvalAccess"]("Receipt")) {
      this.employee = this.$store.state.app.user_data;
      this.getAllLands();
    }
    this.$store.dispatch("master/getCustomerByLandSales");
  },
  methods: {
    setLandOptions() {
      let lands = this.allLands.map((item) => item.villagename);
      this.landOptions = Array.from(new Set(lands));
    },
    handleclickLand() {
      this.surveynonew = "";
      this.survayNoOption = this.allLands.filter((item) => item.villagename == this.land);
    },
    async getEmployee(e) {
      this.employeeOptions = [];
      this.employee = "";
      this.surveynonew = {};
      this.land = "";
      this.landOptions = [];
      this.sales = {};
      if (e && e.id != "admin") {
        let obj = {
          channelpartner: "getChannelpartner",
          franchise: "getFranchise",
          employee: "getEmployee",
        };
        await axios({
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/${obj[e.id]}`,
        }).then((response) => {
          this.employeeOptions = response.data.data;
        });
      } else if (e) {
        await axios({
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getalladmins`,
        }).then((response) => {
          this.employee =
            response.data && response.data.length > 0 ? response.data[0] : {};

          this.getClients(this.employee);
        });
      }
    },
    async getAllLands() {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getLandsByUser`,
        data: {
          user_id: this.employee?.id,
          client_id: this.client?.id,
        },
      };
      await axios(requestOptions)
        .then((response) => {
          this.allLands = response.data.data;
          this.setLandOptions();
        })
        .catch((error) => console.log(error, "error"));
    },
    clickExcel() {
      var aoa = [
        ["Client :", this.sales.customer ? this.sales.customer.fullname : ""],
        ["Village Name :", this.land_data ? this.land_data.villagename : ""],
        [
          "New Survay No. :",
          this.land_data ? this.land_data.surveynonew : "",
          "Land Deal Amount :",
          this.sales.totalamount,
        ],
        ["Zone:", this.land_data.zone?.name, "With NA?:", this.land_data.withna],
        [
          "Land area (in sq.yard):",
          this.land_data.fparea,
          "Down Payment:",
          this.sales.firstpayment ? this.sales.firstpayment : "",
        ],
        [
          "Rate per sq. yard :",
          this.sales.landrate,
          "TP:",
          this.land_data ? this.land_data.tpno : "",
        ],
        [
          "Payment Schedule :",
          this.sales.paymenttermday
            ? this.sales.paymenttermday +
              (this.sales.paymenttermday && this.sales.paymenttermday.includes("day")
                ? ""
                : " days")
            : "",
          "Sub TP:",
          this.land_data ? this.land_data.subtpno : "",
        ],
        [
          "Booking Date :",
          moment(this.sales.bookingdate).format("DD/MM/YYY"),
          "Sale by Name & Team:",
          this.teamname,
        ],
        ["Remarks:", this.sales.remarks],
        [],
        [],
        [],
        ["Sr. No", "Type", "Date", "Payment", "Total Received", "Balance Amount"],
      ];
      this.installment_items.map((item, index) => {
        aoa.push([
          index + 1,
          item.type,
          item.installment_date,
          item.installment_amount,
          item.paid,
          item.balance,
        ]);
      });

      var ws = XLSX.utils.aoa_to_sheet(aoa);
      var html_string = XLSX.utils.sheet_to_html(ws, {
        id: "data-table",
        editable: true,
      });
      document.getElementById("container").innerHTML = html_string;
      function doit(type, fn) {
        var elt = document.getElementById("data-table");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        XLSX.writeFile(
          wb,
          fn ||
            "land statement -" +
              this.land_data.surveynonew +
              " (" +
              this.land_data.villagename +
              ")." +
              (type || "xlsx")
        );
      }
      doit(
        "xlsx",
        "land statement -" +
          this.land_data.surveynonew +
          " (" +
          this.land_data.villagename +
          ").xlsx"
      );
    },
    async searchData() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          const data = {
            land: this.land,
            suveyno: this.surveynonew ? this.surveynonew.surveynonew : "",
          };
          await axios({
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
            url: `${this.baseApi}/landstatement`,
          }).then((response) => {
            this.sales = response.data?.data?.sales;
            this.land_data = response.data?.data;
            let paid = 0;
            let emi = 0;
            if (this.sales) {
              let name = this.sales.customer ? this.sales.customer.fullname : "";
              let city = this.sales.customer ? this.sales.customer.curcity : "";
              let email = this.sales.customer ? this.sales.customer.email : "";
              let mobile = this.sales.customer ? this.sales.customer.mobile : "";
              let arr = [name, city, email, mobile].filter((item) => item);

              this.land_data.customer = arr.join(", ");
              let team = this.sales.selectedteam ? this.sales.selectedteam.fullname : "";
              let master_parent = this.land_data.master_parent
                ? this.land_data.master_parent.fullname
                : "";
              this.teamname = team + (master_parent != team ? " - " + master_parent : "");
              let balance = this.sales.totalpayment;
              this.sales.installments.unshift({
                type: "Booking / DP",
                installment_amount: this.sales.firstpayment,
                paid: this.sales.firstpayment,
                installment_date: moment(this.sales.bookingdate).format("DD/MM/YYYY"),
                balance: this.sales.remainingpayment,
              });
              this.installment_items = this.sales.installments.map((item, index) => {
                this.installment_date = item.installment_date;
                item.type = index == 0 ? "Booking / DP" : "Part Payment";
                item.emino = paid && this.sales.bookingtype == "EMI" ? ++emi : "";
                item.installment_date = item.installment_date;
                item.balance = this.roundOfDecimal(
                  parseFloat(balance) - parseFloat(item.installment_amount),
                  2
                );
                item.paid = this.roundOfDecimal(
                  parseFloat(paid) + parseFloat(item.installment_amount),
                  2
                );
                paid += this.roundOfDecimal(parseFloat(item.installment_amount), 2);
                balance -= this.roundOfDecimal(parseFloat(item.installment_amount), 2);
                return item;
              });
            }

            // this.data = response.data.data;
          });
        }
      });
    },
    printData() {
      const options = {
        styles: ["/css/printTable.css"],
      };
      this.$htmlToPaper("all-data", options);

      // newWin.close();
    },
    async getClients() {
      this.projectOptions = [];
      this.project = "";
      this.surveynonew = {};
      this.land = "";
      this.sales = {};
      if (this.employee) {
        this.$store.dispatch("master/getCustomerByLandSales", this.employee.id);
      }
      this.getAllLands();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.upper-body-data {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.upper-body-data td {
  color: black;
}
</style>
